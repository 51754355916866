const commonWords = {
  en: ['the', 'be', 'to', 'of', 'and', 'a', 'in', 'that', 'have', 'i'],
  de: ['der', 'die', 'das', 'und', 'in', 'zu', 'den', 'ist', 'sie', 'mit'],
  es: ['el', 'la', 'de', 'que', 'y', 'en', 'un', 'ser', 'se', 'no'],
  fr: ['le', 'la', 'de', 'et', 'est', 'en', 'un', 'une', 'du', 'dans']
};

export const detectLanguage = (text: string): string => {
  const words = text.toLowerCase().split(/\s+/);
  const scores: { [key: string]: number } = { en: 0, de: 0, es: 0, fr: 0 };

  words.forEach(word => {
    Object.entries(commonWords).forEach(([lang, commonWordList]) => {
      if (commonWordList.includes(word)) {
        scores[lang]++;
      }
    });
  });

  const detectedLang = Object.entries(scores)
    .sort(([, a], [, b]) => b - a)[0][0];

  return detectedLang || 'en';
};