import React from 'react';
import { TrendingUp } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { KeywordDensity } from '../types/TextAnalysis';

interface KeywordAnalysisProps {
  keywords: KeywordDensity[];
}

export function KeywordAnalysis({ keywords }: KeywordAnalysisProps) {
  const { t } = useTranslation();

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
      <div className="flex items-center gap-2 mb-4">
        <TrendingUp className="w-5 h-5 text-blue-600 dark:text-blue-400" />
        <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
          {t('keywords.title')}
        </h2>
      </div>
      
      {keywords.length === 0 ? (
        <p className="text-gray-600 dark:text-gray-400 text-center py-4">
          {t('keywords.noKeywords')}
        </p>
      ) : (
        <div className="space-y-3">
          {keywords.map(({ word, count, percentage }) => (
            <div key={word} className="flex items-center">
              <div className="w-32 truncate text-gray-900 dark:text-white">{word}</div>
              <div className="flex-1">
                <div className="h-2 bg-gray-200 dark:bg-gray-700 rounded-full">
                  <div
                    className="h-2 bg-blue-600 dark:bg-blue-500 rounded-full transition-all duration-300"
                    style={{ width: `${Math.min(percentage * 2, 100)}%` }}
                  />
                </div>
              </div>
              <div className="w-24 text-right text-sm text-gray-600 dark:text-gray-300">
                {count} ({percentage.toFixed(1)}%)
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}