import React from 'react';
import { useTranslation } from 'react-i18next';
import { Facebook, Twitter, Linkedin } from 'lucide-react';

export function Footer() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  const shareUrl = encodeURIComponent(window.location.href);
  const shareTitle = encodeURIComponent('WordCounter.Cloud - Advanced Text Analysis');

  return (
    <footer className="mt-12 py-8 border-t border-border">
      <div className="max-w-6xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Legal Links */}
          <div>
            <h3 className="font-semibold mb-4">{t('footer.legal')}</h3>
            <ul className="space-y-2">
              <li>
                <a href="/privacy" className="text-muted hover:text-primary">
                  {t('footer.privacy')}
                </a>
              </li>
              <li>
                <a href="/imprint" className="text-muted hover:text-primary">
                  {t('footer.imprint')}
                </a>
              </li>
            </ul>
          </div>

          {/* Social Sharing */}
          <div>
            <h3 className="font-semibold mb-4">{t('footer.share')}</h3>
            <div className="flex space-x-4">
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-muted hover:text-primary"
                aria-label="Share on Facebook"
              >
                <Facebook className="w-5 h-5" />
              </a>
              <a
                href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareTitle}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-muted hover:text-primary"
                aria-label="Share on Twitter"
              >
                <Twitter className="w-5 h-5" />
              </a>
              <a
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${shareTitle}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-muted hover:text-primary"
                aria-label="Share on LinkedIn"
              >
                <Linkedin className="w-5 h-5" />
              </a>
            </div>
          </div>

          {/* Copyright */}
          <div className="text-right">
            <p className="text-muted">
              © {currentYear} WordCounter.Cloud
              <br />
              {t('footer.rights')}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}