export const THEME = {
  storage: {
    key: 'theme'
  },
  colors: {
    light: {
      background: '#ffffff',
      text: '#1f2937',
      muted: '#6b7280',
      border: '#e5e7eb'
    },
    dark: {
      background: '#1f2937',
      text: '#f9fafb',
      muted: '#9ca3af',
      border: '#374151'
    }
  },
  mediaQuery: '(prefers-color-scheme: dark)'
} as const;