import { Document, Packer, Paragraph } from 'docx';

export const exportDOCX = async (text: string, formattedStats: string): Promise<Blob> => {
  const doc = new Document({
    sections: [{
      properties: {},
      children: [
        new Paragraph({ text }),
        new Paragraph({ text: '\n' }),
        new Paragraph({ text: formattedStats })
      ]
    }]
  });
  
  return Packer.toBlob(doc);
};