import React, { useRef, useEffect } from 'react';
import { FileText } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { GrammarSuggestion } from '../types/TextAnalysis';

interface TextInputProps {
  value: string;
  onChange: (text: string) => void;
  grammarSuggestions?: GrammarSuggestion[];
}

export function TextInput({ value, onChange, grammarSuggestions }: TextInputProps) {
  const { t } = useTranslation();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [value]);

  return (
    <div className="w-full">
      <div className="flex items-center gap-2 mb-2">
        <FileText className="w-5 h-5 text-primary" />
        <h2 className="text-lg font-semibold">
          {t('app.textEditor')}
        </h2>
      </div>
      <div className="relative">
        <textarea
          ref={textareaRef}
          className="w-full min-h-[12rem] p-4 rounded-lg 
                   bg-surface border border-border
                   focus:ring-2 focus:ring-primary focus:border-transparent 
                   text-foreground placeholder-muted
                   text-base sm:text-base
                   transition-colors duration-200
                   touch-manipulation"
          placeholder={t('app.placeholder')}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          spellCheck="true"
          data-gramm="true"
          style={{
            WebkitOverflowScrolling: 'touch',
          }}
        />
        {grammarSuggestions && grammarSuggestions.length > 0 && (
          <div className="absolute right-2 top-2 space-y-1 max-w-[150px] sm:max-w-[200px]">
            {grammarSuggestions.map((suggestion, index) => (
              <button
                key={index}
                className="w-full text-left px-2 py-1 text-xs sm:text-sm
                         bg-warning/10 text-warning-700 dark:text-warning-300
                         rounded-full hover:bg-warning/20 transition-colors
                         truncate"
                onClick={() => {
                  const newText = value.slice(0, suggestion.offset) +
                                suggestion.suggestions[0] +
                                value.slice(suggestion.offset + suggestion.length);
                  onChange(newText);
                }}
              >
                {suggestion.suggestions[0]}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}