import { jsPDF } from 'jspdf';
import i18n from '../../i18n';

export const createPDFFooter = (pdf: jsPDF, language: string): void => {
  const pageCount = pdf.internal.getNumberOfPages();
  
  for (let i = 1; i <= pageCount; i++) {
    pdf.setPage(i);
    pdf.setFontSize(10);
    pdf.setTextColor(156, 163, 175); // Gray text
    pdf.text(
      `${i18n.t('app.title', { lng: language })} - ${i18n.t('app.page', { lng: language })} ${i} / ${pageCount}`,
      10,
      pdf.internal.pageSize.height - 10
    );
  }
};