import React, { useRef } from 'react';
import { Menu } from '@headlessui/react';
import { Upload, Download, Save, MoreVertical } from 'lucide-react';
import { exportText, importText } from '../utils/fileHandling';
import { TextStatistics } from '../types/TextAnalysis';
import { useTranslation } from 'react-i18next';
import { Toast } from './Toast';

interface FileControlsProps {
  text: string;
  stats: TextStatistics;
  onTextImport: (text: string) => void;
  onSave: () => void;
}

export function FileControls({ text, stats, onTextImport, onSave }: FileControlsProps) {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState('');

  const handleImport = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const content = await importText(file);
      onTextImport(content);
    }
  };

  const handleExport = async (format: 'txt' | 'docx' | 'pdf') => {
    await exportText(text, 'text-analysis', format, stats);
  };

  const handleSave = () => {
    onSave();
    setToastMessage(t('autosave.message'));
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  return (
    <>
      <div className="flex items-center gap-2">
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleImport}
          accept=".txt,.md,.doc,.docx"
          className="hidden"
        />
        
        {/* Mobile View */}
        <div className="sm:hidden">
          <Menu as="div" className="relative">
            <Menu.Button className="p-2 rounded-lg bg-surface hover:bg-surface/90">
              <MoreVertical className="w-5 h-5" />
            </Menu.Button>
            <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right bg-white dark:bg-gray-800 rounded-lg shadow-lg py-1 z-50">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => fileInputRef.current?.click()}
                    className={`${active ? 'bg-gray-100 dark:bg-gray-700' : ''} w-full text-left px-4 py-2 text-sm`}
                  >
                    {t('actions.import')}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => handleExport('txt')}
                    className={`${active ? 'bg-gray-100 dark:bg-gray-700' : ''} w-full text-left px-4 py-2 text-sm`}
                  >
                    {t('actions.exportTxt')}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => handleExport('docx')}
                    className={`${active ? 'bg-gray-100 dark:bg-gray-700' : ''} w-full text-left px-4 py-2 text-sm`}
                  >
                    {t('actions.exportDocx')}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => handleExport('pdf')}
                    className={`${active ? 'bg-gray-100 dark:bg-gray-700' : ''} w-full text-left px-4 py-2 text-sm`}
                  >
                    {t('actions.exportPdf')}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleSave}
                    className={`${active ? 'bg-gray-100 dark:bg-gray-700' : ''} w-full text-left px-4 py-2 text-sm`}
                  >
                    {t('actions.save')}
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>

        {/* Desktop View */}
        <div className="hidden sm:flex sm:items-center sm:gap-2">
          <button
            onClick={() => fileInputRef.current?.click()}
            className="flex items-center gap-1 px-3 py-2 text-sm bg-surface hover:bg-surface/90 rounded-lg"
          >
            <Upload className="w-4 h-4" />
            <span>{t('actions.import')}</span>
          </button>

          <Menu as="div" className="relative">
            <Menu.Button className="flex items-center gap-1 px-3 py-2 text-sm bg-surface hover:bg-surface/90 rounded-lg">
              <Download className="w-4 h-4" />
              <span>{t('actions.export')}</span>
            </Menu.Button>
            <Menu.Items className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg py-1 z-50">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => handleExport('txt')}
                    className={`${active ? 'bg-gray-100 dark:bg-gray-700' : ''} w-full text-left px-4 py-2 text-sm`}
                  >
                    {t('actions.exportTxt')}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => handleExport('docx')}
                    className={`${active ? 'bg-gray-100 dark:bg-gray-700' : ''} w-full text-left px-4 py-2 text-sm`}
                  >
                    {t('actions.exportDocx')}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => handleExport('pdf')}
                    className={`${active ? 'bg-gray-100 dark:bg-gray-700' : ''} w-full text-left px-4 py-2 text-sm`}
                  >
                    {t('actions.exportPdf')}
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Menu>

          <button
            onClick={handleSave}
            className="flex items-center gap-1 px-3 py-2 text-sm bg-primary text-white hover:bg-primary/90 rounded-lg"
          >
            <Save className="w-4 h-4" />
            <span>{t('actions.save')}</span>
          </button>
        </div>
      </div>
      {showToast && <Toast message={toastMessage} />}
    </>
  );
}