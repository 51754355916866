export const calculateReadabilityScore = (text: string): number => {
  const words = text.trim().split(/\s+/).filter(word => word.length > 0);
  const sentences = text.split(/[.!?]+/).filter(sentence => sentence.trim().length > 0);
  const syllables = words.reduce((count, word) => count + countSyllables(word), 0);

  // Flesch Reading Ease score
  const wordsPerSentence = words.length / Math.max(sentences.length, 1);
  const syllablesPerWord = syllables / Math.max(words.length, 1);
  
  return Math.max(0, Math.min(100,
    206.835 - (1.015 * wordsPerSentence) - (84.6 * syllablesPerWord)
  ));
};

const countSyllables = (word: string): number => {
  word = word.toLowerCase();
  if (word.length <= 3) return 1;

  word = word.replace(/(?:[^laeiouy]es|ed|[^laeiouy]e)$/, '');
  word = word.replace(/^y/, '');

  const syllableCount = word.match(/[aeiouy]{1,2}/g)?.length || 0;
  return Math.max(1, syllableCount);
};