import { useEffect } from 'react';
import type { TextStatistics } from '../types/TextAnalysis';

const AUTO_SAVE_KEY = 'word-counter-autosave';
const AUTO_SAVE_INTERVAL = 30000; // 30 seconds

interface AutoSaveData {
  text: string;
  timestamp: number;
  statistics: TextStatistics;
}

export function useAutoSave(text: string, stats: TextStatistics) {
  useEffect(() => {
    // Load saved data on mount
    const savedData = localStorage.getItem(AUTO_SAVE_KEY);
    if (savedData) {
      const { text: savedText } = JSON.parse(savedData) as AutoSaveData;
      // You can handle the saved text here if needed
    }

    const interval = setInterval(() => {
      if (text) {
        const data: AutoSaveData = {
          text,
          timestamp: Date.now(),
          statistics: stats
        };
        localStorage.setItem(AUTO_SAVE_KEY, JSON.stringify(data));
      }
    }, AUTO_SAVE_INTERVAL);

    return () => clearInterval(interval);
  }, [text, stats]);

  const handleSave = () => {
    const data: AutoSaveData = {
      text,
      timestamp: Date.now(),
      statistics: stats
    };
    localStorage.setItem(AUTO_SAVE_KEY, JSON.stringify(data));
  };

  return { handleSave };
}