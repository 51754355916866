import { TextStatistics } from '../types/TextAnalysis';
import i18n from '../i18n';

export const formatStatistics = (stats: TextStatistics): string => {
  const t = i18n.getFixedT(stats.language);
  
  return [
    t('stats.summary'),
    '------------------------',
    `${t('stats.words')}: ${stats.wordCount}`,
    `${t('stats.characters')}: ${stats.characterCount}`,
    `${t('stats.charactersNoSpaces')}: ${stats.characterNoSpaces}`,
    `${t('stats.sentences')}: ${stats.sentenceCount}`,
    `${t('stats.paragraphs')}: ${stats.paragraphCount}`,
    `${t('stats.readingTime')}: ${stats.readingTime} ${t('stats.minutes')}`,
    `${t('stats.speakingTime')}: ${stats.speakingTime} ${t('stats.minutes')}`,
    '',
    t('details.title'),
    '------------------------',
    `${t('details.avgSentenceChars')}: ${stats.avgSentenceChars}`,
    `${t('details.avgSentenceWords')}: ${stats.avgSentenceWords}`,
    `${t('details.avgWordLength')}: ${stats.avgWordLength.toFixed(1)}`,
    `${t('details.longestSentence')}: ${stats.longestSentence}`,
    `${t('details.shortestSentence')}: ${stats.shortestSentence}`,
    `${t('details.syllables')}: ${stats.syllableCount}`,
    `${t('details.uniqueWords')}: ${stats.uniqueWords}`,
    `${t('details.pages')}: ${stats.pages}`,
    `${t('details.lines')}: ${stats.lines}`,
    `${t('details.handWritingTime')}: ${stats.handWritingTime} ${t('stats.minutes')}`,
  ].join('\n');
};