import React from 'react';
import { Settings2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { TextStatistics } from '../types/TextAnalysis';
import { calculateAdvancedStats } from '../utils/textAnalysis/advancedAnalysis';

interface DetailsPanelProps {
  text: string;
  stats: TextStatistics;
}

export function DetailsPanel({ text, stats }: DetailsPanelProps) {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const advancedStats = calculateAdvancedStats(text);

  const StatItem = ({ label, value }: { label: string; value: string | number }) => (
    <div className="flex justify-between items-center py-2 border-b dark:border-gray-700">
      <span className="text-gray-600 dark:text-gray-300">{label}</span>
      <span className="font-medium text-gray-900 dark:text-white">
        {typeof value === 'number' && label.includes('Time') 
          ? `${value} ${t('stats.minutes')}`
          : value}
      </span>
    </div>
  );

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 w-full text-left mb-2"
      >
        <Settings2 className="w-5 h-5 text-blue-600 dark:text-blue-400" />
        <span className="text-lg font-semibold text-gray-900 dark:text-white">
          {t('details.title')}
        </span>
      </button>
      
      {isOpen && (
        <div className="space-y-1 mt-4">
          <StatItem 
            label={t('details.avgSentenceChars')} 
            value={advancedStats.avgSentenceChars} 
          />
          <StatItem 
            label={t('details.avgSentenceWords')} 
            value={advancedStats.avgSentenceWords} 
          />
          <StatItem 
            label={t('details.avgWordLength')} 
            value={advancedStats.avgWordLength.toFixed(1)} 
          />
          <StatItem 
            label={t('details.longestSentence')} 
            value={advancedStats.longestSentence} 
          />
          <StatItem 
            label={t('details.shortestSentence')} 
            value={advancedStats.shortestSentence} 
          />
          <StatItem 
            label={t('details.syllables')} 
            value={advancedStats.syllableCount} 
          />
          <StatItem 
            label={t('details.uniqueWords')} 
            value={advancedStats.uniqueWords} 
          />
          <StatItem 
            label={t('details.pages')} 
            value={advancedStats.pages} 
          />
          <StatItem 
            label={t('details.lines')} 
            value={advancedStats.lines} 
          />
          <StatItem 
            label={t('details.handWritingTime')} 
            value={advancedStats.handWritingTime} 
          />
        </div>
      )}
    </div>
  );
}