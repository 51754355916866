import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from '@headlessui/react';
import { Globe } from 'lucide-react';

const languages = [
  { code: 'en', name: 'English' },
  { code: 'de', name: 'Deutsch' },
  { code: 'es', name: 'Español' },
  { code: 'fr', name: 'Français' }
];

export function LanguageSelector() {
  const { i18n } = useTranslation();

  return (
    <Menu as="div" className="relative">
      <Menu.Button className="p-2 rounded-lg bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600">
        <Globe className="w-5 h-5 text-gray-700 dark:text-white" />
      </Menu.Button>
      <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right
                           bg-white dark:bg-gray-800 rounded-lg shadow-lg py-1 z-50
                           transform -translate-x-0 md:translate-x-0">
        {languages.map((lang) => (
          <Menu.Item key={lang.code}>
            {({ active }) => (
              <button
                className={`${
                  active ? 'bg-gray-100 dark:bg-gray-700' : ''
                } ${
                  i18n.language === lang.code ? 'font-bold' : ''
                } w-full text-left px-4 py-2 text-gray-900 dark:text-white`}
                onClick={() => i18n.changeLanguage(lang.code)}
              >
                {lang.name}
              </button>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
}