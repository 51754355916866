import { GrammarSuggestion } from '../types/TextAnalysis';

const commonErrors = {
  en: [
    { pattern: /\b(its|it's)\b/g, check: (match: string, context: string) => {
      const possessive = context.includes("its " + context.split("its ")[1]?.split(" ")[0]);
      return match === (possessive ? "its" : "it's") ? null : {
        text: match,
        suggestions: [possessive ? "its" : "it's"],
        type: 'grammar'
      };
    }},
    { pattern: /\b(there|their|they're)\b/g, check: (match: string, context: string) => {
      // Simplified check - in a real app, this would be more sophisticated
      return {
        text: match,
        suggestions: ['there', 'their', "they're"].filter(s => s !== match),
        type: 'grammar'
      };
    }},
    { pattern: /\b(your|you're)\b/g, check: (match: string, context: string) => {
      const possessive = /your\s+\w+/.test(context);
      return match === (possessive ? "your" : "you're") ? null : {
        text: match,
        suggestions: [possessive ? "your" : "you're"],
        type: 'grammar'
      };
    }},
    { pattern: /\b(affect|effect)\b/g, check: (match: string, context: string) => {
      // Simplified - would need more context analysis in a real app
      return {
        text: match,
        suggestions: ['affect', 'effect'].filter(s => s !== match),
        type: 'grammar'
      };
    }},
    { pattern: /\b(then|than)\b/g, check: (match: string, context: string) => {
      const comparison = /\b(more|less|better|worse|rather|other)\s+than\b/.test(context);
      return match === (comparison ? "than" : "then") ? null : {
        text: match,
        suggestions: [comparison ? "than" : "then"],
        type: 'grammar'
      };
    }},
    // Double words
    { pattern: /\b(\w+)\s+\1\b/g, check: (match: string) => ({
      text: match,
      suggestions: [match.split(/\s+/)[0]],
      type: 'grammar'
    })},
    // Missing apostrophes in contractions
    { pattern: /\b(cant|dont|wouldnt|shouldnt|couldnt|wont|isnt|arent|hasnt|havent|didnt)\b/g,
      check: (match: string) => ({
        text: match,
        suggestions: [match.slice(0, -2) + "'" + match.slice(-2)],
        type: 'grammar'
      })
    }
  ],
  de: [
    // German grammar rules would go here
  ],
  es: [
    // Spanish grammar rules would go here
  ],
  fr: [
    // French grammar rules would go here
  ]
};

export const checkGrammar = (text: string, language: string): GrammarSuggestion[] => {
  const suggestions: GrammarSuggestion[] = [];
  const rules = commonErrors[language as keyof typeof commonErrors] || commonErrors.en;

  rules.forEach(rule => {
    let match;
    while ((match = rule.pattern.exec(text)) !== null) {
      const suggestion = rule.check(match[0], text);
      if (suggestion) {
        suggestions.push({
          ...suggestion,
          offset: match.index,
          length: match[0].length,
        });
      }
    }
  });

  return suggestions;
};