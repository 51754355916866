import { detectLanguage } from './languageDetection';
import { calculateReadabilityScore } from './readability';
import { TextStatistics, KeywordDensity } from '../types/TextAnalysis';

export const calculateTextStatistics = (text: string): TextStatistics => {
  const words = text.trim().split(/\s+/).filter(word => word.length > 0);
  const sentences = text.split(/[.!?]+/).filter(sentence => sentence.trim().length > 0);
  const paragraphs = text.split(/\n\s*\n/).filter(para => para.trim().length > 0);
  const charactersNoSpaces = text.replace(/\s/g, '').length;

  return {
    wordCount: words.length,
    characterCount: text.length,
    characterNoSpaces: charactersNoSpaces,
    sentenceCount: sentences.length,
    paragraphCount: paragraphs.length,
    readingTime: Math.ceil(words.length / 200), // Average reading speed: 200 wpm
    speakingTime: Math.ceil(words.length / 130), // Average speaking speed: 130 wpm
    readabilityScore: calculateReadabilityScore(text),
    language: detectLanguage(text),
  };
};

export const analyzeKeywordDensity = (text: string): Array<KeywordDensity> => {
  const words = text.toLowerCase()
    .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
    .split(/\s+/)
    .filter(word => word.length > 0);

  const wordCount = words.length;
  const frequency: { [key: string]: number } = {};

  words.forEach(word => {
    frequency[word] = (frequency[word] || 0) + 1;
  });

  return Object.entries(frequency)
    .map(([word, count]) => ({
      word,
      count,
      percentage: (count / wordCount) * 100
    }))
    .sort((a, b) => b.count - a.count)
    .slice(0, 10);
};