import React, { Suspense } from 'react';
import { TextInput } from './components/TextInput';
import { Statistics } from './components/Statistics';
import { KeywordAnalysis } from './components/KeywordAnalysis';
import { ReadabilityIndicator } from './components/ReadabilityIndicator';
import { StyleSuggestions } from './components/StyleSuggestions';
import { DetailsPanel } from './components/DetailsPanel';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { CookieConsent } from './components/CookieConsent';
import { useTextAnalysis } from './hooks/useTextAnalysis';
import { useNavigation } from './hooks/useNavigation';
import { useAutoSave } from './hooks/useAutoSave';
import { analyzeKeywordDensity } from './utils/textAnalysis/keywordAnalysis';

function App() {
  const { text, setText, stats, grammarSuggestions, styleSuggestions } = useTextAnalysis();
  const { handleNavigate } = useNavigation();
  const { handleSave } = useAutoSave(text, stats);
  const keywords = React.useMemo(() => analyzeKeywordDensity(text), [text]);

  return (
    <div className="min-h-screen bg-bg theme-transition">
      <Suspense fallback={<div className="h-screen w-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>}>
        <div className="max-w-6xl mx-auto px-4 py-8 space-y-6">
          <Header 
            onSave={handleSave}
            text={text}
            stats={stats}
            onTextImport={setText}
          />
          
          <main className="space-y-6">
            <div id="editor">
              <TextInput
                value={text}
                onChange={setText}
                grammarSuggestions={grammarSuggestions}
              />
            </div>
            
            <div id="statistics">
              <Statistics stats={stats} />
            </div>
            
            {text.length > 0 && (
              <>
                <div id="readability">
                  <ReadabilityIndicator score={stats.readabilityScore} />
                </div>
                
                <div id="keywords">
                  <KeywordAnalysis keywords={keywords} />
                </div>
                
                {styleSuggestions.length > 0 && (
                  <div id="style">
                    <StyleSuggestions suggestions={styleSuggestions} />
                  </div>
                )}
              </>
            )}
            
            <div className="lg:col-span-1">
              <DetailsPanel text={text} stats={stats} />
            </div>
          </main>

          <Footer />
        </div>
        <CookieConsent />
      </Suspense>
    </div>
  );
}

export default App;