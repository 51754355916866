import React from 'react';
import { BookOpen } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface ReadabilityIndicatorProps {
  score: number;
}

export function ReadabilityIndicator({ score }: ReadabilityIndicatorProps) {
  const { t } = useTranslation();

  const getReadabilityLevel = (score: number) => {
    if (score >= 90) return t('readability.veryEasy');
    if (score >= 80) return t('readability.easy');
    if (score >= 70) return t('readability.fairlyEasy');
    if (score >= 60) return t('readability.standard');
    if (score >= 50) return t('readability.fairlyDifficult');
    if (score >= 30) return t('readability.difficult');
    return t('readability.veryDifficult');
  };

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
      <div className="flex items-center gap-2 mb-4">
        <BookOpen className="w-5 h-5 text-blue-600 dark:text-blue-400" />
        <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
          {t('readability.title')}
        </h2>
      </div>
      <div className="space-y-2">
        <div className="flex justify-between items-center">
          <span className="text-gray-600 dark:text-gray-300">
            {getReadabilityLevel(score)}
          </span>
          <span className="font-bold text-gray-900 dark:text-white">
            {Math.round(score)}/100
          </span>
        </div>
        <div className="h-2 bg-gray-200 dark:bg-gray-700 rounded-full">
          <div
            className="h-2 bg-blue-600 dark:bg-blue-500 rounded-full transition-all duration-300"
            style={{ width: `${score}%` }}
          />
        </div>
      </div>
    </div>
  );
}