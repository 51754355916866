import thesaurus from 'thesaurus-com';

export const getSynonyms = async (word: string): Promise<string[]> => {
  try {
    const results = await thesaurus.search(word);
    return results.slice(0, 5); // Return top 5 synonyms
  } catch {
    return [];
  }
};

export const getStyleSuggestions = async (text: string): Promise<Array<{ original: string; suggestion: string; reason: string }>> => {
  const suggestions = [];
  
  // Common weak words and their alternatives
  const weakWords = {
    'very': ['extremely', 'particularly', 'especially'],
    'really': ['genuinely', 'truly', 'absolutely'],
    'good': ['excellent', 'outstanding', 'exceptional'],
    'bad': ['poor', 'inadequate', 'subpar'],
    'nice': ['pleasant', 'delightful', 'agreeable'],
    'thing': ['aspect', 'element', 'component'],
    'a lot': ['numerous', 'many', 'abundant'],
  };

  // Process weak words
  for (const [weak, alternatives] of Object.entries(weakWords)) {
    const regex = new RegExp(`\\b${weak}\\b`, 'gi');
    let match;
    while ((match = regex.exec(text)) !== null) {
      suggestions.push({
        original: match[0],
        suggestion: alternatives[0],
        reason: `Consider using a more specific word like "${alternatives.join('" or "')}" instead of "${weak}".`
      });
    }
  }

  // Check for passive voice
  const passiveRegex = /\b(am|is|are|was|were|be|been|being)\s+(\w+ed)\b/g;
  let match;
  while ((match = passiveRegex.exec(text)) !== null) {
    suggestions.push({
      original: match[0],
      suggestion: 'active voice',
      reason: 'Consider using active voice for more direct and engaging writing.'
    });
  }

  // Try to get synonyms for common words
  const words = text.toLowerCase().split(/\s+/);
  const commonWords = new Set(words.filter(word => 
    word.length > 4 && 
    !Object.keys(weakWords).includes(word)
  ).slice(0, 5));

  const synonymPromises = Array.from(commonWords).map(async word => {
    const synonyms = await getSynonyms(word);
    if (synonyms.length > 0) {
      suggestions.push({
        original: word,
        suggestion: synonyms[0],
        reason: `Consider using a synonym like "${synonyms.join('" or "')}" for variety.`
      });
    }
  });

  await Promise.all(synonymPromises);

  return suggestions;
};