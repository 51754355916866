import { jsPDF } from 'jspdf';
import i18n from '../../i18n';
import { TextStatistics } from '../../types/TextAnalysis';

export const createPDFContent = (
  pdf: jsPDF,
  text: string,
  stats: TextStatistics,
  formattedStats: string,
  startY: number
): void => {
  let yPosition = startY;

  // Add divider
  pdf.setDrawColor(229, 231, 235); // Light gray
  pdf.line(10, yPosition, pdf.internal.pageSize.width - 10, yPosition);
  yPosition += 10;

  // Add content
  pdf.setTextColor(31, 41, 55); // Reset text color
  pdf.setFont('Helvetica', 'normal');
  pdf.setFontSize(12);

  // Add text content
  const splitText = pdf.splitTextToSize(text, pdf.internal.pageSize.width - 20);
  splitText.forEach((line: string) => {
    if (yPosition > pdf.internal.pageSize.height - 20) {
      pdf.addPage();
      yPosition = 20;
    }
    pdf.text(line, 10, yPosition);
    yPosition += 7;
  });

  // Add statistics
  yPosition += 10;
  if (yPosition > pdf.internal.pageSize.height - 60) {
    pdf.addPage();
    yPosition = 20;
  }

  // Add statistics header
  pdf.setFont('Helvetica', 'bold');
  pdf.setFontSize(14);
  pdf.text(i18n.t('stats.summary', { lng: stats.language }), 10, yPosition);
  yPosition += 10;

  // Add statistics content
  pdf.setFont('Helvetica', 'normal');
  pdf.setFontSize(12);
  const splitStats = pdf.splitTextToSize(formattedStats, pdf.internal.pageSize.width - 20);
  splitStats.forEach((line: string) => {
    if (yPosition > pdf.internal.pageSize.height - 20) {
      pdf.addPage();
      yPosition = 20;
    }
    pdf.text(line, 10, yPosition);
    yPosition += 7;
  });
};