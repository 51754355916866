import React from 'react';
import { useTranslation } from 'react-i18next';

export function CookieConsent() {
  const { t } = useTranslation();
  const [show, setShow] = React.useState(() => !localStorage.getItem('cookie-consent'));

  const handleAccept = () => {
    localStorage.setItem('cookie-consent', 'true');
    localStorage.setItem('analytics-consent', 'true');
    setShow(false);
    window.location.reload(); // Reload to initialize analytics
  };

  const handleDecline = () => {
    localStorage.setItem('cookie-consent', 'true');
    localStorage.setItem('analytics-consent', 'false');
    setShow(false);
  };

  if (!show) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 border-t border-border p-4 z-50">
      <div className="max-w-6xl mx-auto flex flex-col sm:flex-row items-center justify-between gap-4">
        <div className="text-sm">
          <p>{t('cookies.notice')}</p>
          <a href="/privacy" className="text-primary hover:underline">
            {t('cookies.learnMore')}
          </a>
        </div>
        <div className="flex gap-4">
          <button
            onClick={handleDecline}
            className="px-4 py-2 text-sm bg-surface hover:bg-surface/90 rounded-lg"
          >
            {t('cookies.decline')}
          </button>
          <button
            onClick={handleAccept}
            className="px-4 py-2 text-sm bg-primary text-white hover:bg-primary/90 rounded-lg"
          >
            {t('cookies.accept')}
          </button>
        </div>
      </div>
    </div>
  );
}