import React from 'react';
import { useTranslation } from 'react-i18next';
import { Logo } from './Logo';
import { ThemeToggle } from './ThemeToggle';
import { LanguageSelector } from './LanguageSelector';
import { FileControls } from './FileControls';

interface HeaderProps {
  onSave?: () => void;
  text?: string;
  stats?: any;
  onTextImport?: (text: string) => void;
}

export function Header({ onSave, text, stats, onTextImport }: HeaderProps) {
  const { t } = useTranslation();

  return (
    <header className="flex flex-col sm:flex-row items-center gap-4 mb-8">
      <div className="flex-shrink-0">
        <Logo />
      </div>
      
      <div className="flex flex-wrap items-center justify-end gap-2 ml-auto">
        <ThemeToggle />
        <LanguageSelector />
        {onSave && text && stats && onTextImport && (
          <FileControls
            text={text}
            stats={stats}
            onTextImport={onTextImport}
            onSave={onSave}
          />
        )}
      </div>
    </header>
  );
}