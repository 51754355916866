import React from 'react';
import { Lightbulb } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface StyleSuggestionsProps {
  suggestions: Array<{
    original: string;
    suggestion: string;
    reason: string;
  }>;
}

export function StyleSuggestions({ suggestions }: StyleSuggestionsProps) {
  const { t } = useTranslation();

  if (suggestions.length === 0) return null;

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
      <div className="flex items-center gap-2 mb-4">
        <Lightbulb className="w-5 h-5 text-yellow-500" />
        <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
          Style Suggestions
        </h2>
      </div>
      <div className="space-y-4">
        {suggestions.map((suggestion, index) => (
          <div key={index} className="p-4 bg-yellow-50 dark:bg-gray-700/50 rounded-lg">
            <p className="text-sm text-gray-700 dark:text-gray-200">
              <span className="font-medium">Original:</span> "{suggestion.original}"
            </p>
            <p className="text-sm text-gray-700 dark:text-gray-200 mt-1">
              <span className="font-medium">Suggestion:</span> "{suggestion.suggestion}"
            </p>
            <p className="text-sm text-gray-600 dark:text-gray-300 mt-2">
              {suggestion.reason}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}