import { jsPDF } from 'jspdf';
import { TextStatistics } from '../../types/TextAnalysis';
import { createPDFHeader } from '../pdf/createPDFHeader';
import { createPDFContent } from '../pdf/createPDFContent';
import { createPDFFooter } from '../pdf/createPDFFooter';

export const exportPDF = async (
  text: string,
  formattedStats: string,
  stats: TextStatistics
): Promise<Blob> => {
  const pdf = new jsPDF();
  
  // Create PDF structure
  const startY = createPDFHeader(pdf, stats.language);
  createPDFContent(pdf, text, stats, formattedStats, startY);
  createPDFFooter(pdf, stats.language);
  
  return new Blob([pdf.output('blob')], { type: 'application/pdf' });
};