import React, { createContext, useContext, useEffect, useState } from 'react';
import { THEME } from '../constants/theme';

interface ThemeContextType {
  isDark: boolean;
  toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  // Initialize with light theme by default
  const [isDark, setIsDark] = useState(() => {
    if (typeof window === 'undefined') return false;
    
    const savedTheme = localStorage.getItem(THEME.storage.key);
    // Return false (light theme) if no theme is saved
    return savedTheme === 'dark';
  });

  useEffect(() => {
    // Apply theme class to document
    document.documentElement.classList.toggle('dark', isDark);
    
    // Update theme color meta tag
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute(
        'content',
        isDark ? THEME.colors.dark.background : THEME.colors.light.background
      );
    }
    
    // Save theme preference
    localStorage.setItem(THEME.storage.key, isDark ? 'dark' : 'light');
  }, [isDark]);

  const toggleTheme = () => setIsDark(prev => !prev);

  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}