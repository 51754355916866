interface AdvancedStats {
  avgSentenceChars: number;
  avgSentenceWords: number;
  avgWordLength: number;
  longestSentence: number;
  shortestSentence: number;
  syllableCount: number;
  uniqueWords: number;
  pages: number;
  lines: number;
  handWritingTime: number;
}

const countSyllables = (word: string): number => {
  word = word.toLowerCase();
  if (word.length <= 3) return 1;
  
  word = word.replace(/(?:[^laeiouy]es|ed|[^laeiouy]e)$/, '');
  word = word.replace(/^y/, '');
  
  const syllableCount = word.match(/[aeiouy]{1,2}/g)?.length || 0;
  return Math.max(1, syllableCount);
};

export const calculateAdvancedStats = (text: string): AdvancedStats => {
  if (!text.trim()) {
    return {
      avgSentenceChars: 0,
      avgSentenceWords: 0,
      avgWordLength: 0,
      longestSentence: 0,
      shortestSentence: 0,
      syllableCount: 0,
      uniqueWords: 0,
      pages: 0,
      lines: 0,
      handWritingTime: 0
    };
  }

  const sentences = text.split(/[.!?]+/).filter(s => s.trim().length > 0);
  const words = text.trim().split(/\s+/).filter(w => w.length > 0);
  const uniqueWordsSet = new Set(words.map(w => w.toLowerCase()));
  const lines = text.split('\n').length;
  
  const sentenceLengths = sentences.map(s => s.split(/\s+/).filter(w => w.length > 0).length);
  const totalSyllables = words.reduce((sum, word) => sum + countSyllables(word), 0);
  
  const avgSentenceWords = sentences.length ? words.length / sentences.length : 0;
  const avgSentenceChars = sentences.length ? text.length / sentences.length : 0;
  const avgWordLength = words.length ? text.length / words.length : 0;
  
  // Assuming 250 words per page (standard manuscript)
  const pages = Math.ceil(words.length / 250);
  
  // Average handwriting speed: 68 letters per minute
  const handWritingTime = Math.ceil(text.length / 68);

  return {
    avgSentenceChars: Math.round(avgSentenceChars),
    avgSentenceWords: Math.round(avgSentenceWords),
    avgWordLength,
    longestSentence: Math.max(...sentenceLengths),
    shortestSentence: Math.min(...sentenceLengths),
    syllableCount: totalSyllables,
    uniqueWords: uniqueWordsSet.size,
    pages,
    lines,
    handWritingTime
  };
};