import { jsPDF } from 'jspdf';
import i18n from '../../i18n';

export const createPDFHeader = (pdf: jsPDF, language: string): number => {
  // Add logo
  const logoSize = 20;
  pdf.setFillColor(37, 99, 235); // Primary blue color
  pdf.rect(10, 10, logoSize, logoSize, 'F');
  
  // Add FileText icon representation
  pdf.setTextColor(255, 255, 255);
  pdf.setFont('Helvetica', 'bold');
  pdf.setFontSize(14);
  pdf.text('Aa', 15, 23);

  // Add title
  pdf.setTextColor(31, 41, 55); // Dark text color
  pdf.setFont('Helvetica', 'bold');
  pdf.setFontSize(24);
  pdf.text('WordCounter.Cloud', 40, 25);

  // Add subtitle in the detected language
  pdf.setFontSize(12);
  pdf.setTextColor(107, 114, 128); // Gray text color
  pdf.text(i18n.t('app.title', { lng: language }), 40, 32);

  return 45; // Return the Y position after the header
};