import { TextStatistics } from '../types/TextAnalysis';
import { formatStatistics } from './statisticsFormatter';
import { exportPDF } from './export/exportPDF';
import { exportDOCX } from './export/exportDOCX';

export const exportText = async (
  text: string,
  filename: string,
  format: 'txt' | 'docx' | 'pdf',
  stats: TextStatistics
) => {
  try {
    const formattedStats = formatStatistics(stats);
    const fullContent = `${text}\n\n${formattedStats}`;
    let blob: Blob;

    switch (format) {
      case 'txt':
        blob = new Blob([fullContent], { type: 'text/plain;charset=utf-8' });
        break;

      case 'docx':
        blob = await exportDOCX(text, formattedStats);
        break;

      case 'pdf':
        blob = await exportPDF(text, formattedStats, stats);
        break;

      default:
        throw new Error('Unsupported format');
    }

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${filename}.${format}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error exporting file:', error);
    throw error;
  }
};

export const importText = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve(e.target?.result as string);
    reader.onerror = reject;
    reader.readAsText(file);
  });
};