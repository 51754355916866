import { useState, useEffect } from 'react';
import { calculateTextStatistics } from '../utils/textAnalysis';
import { checkGrammar } from '../utils/grammar';
import { getStyleSuggestions } from '../utils/synonyms';
import type { TextStatistics, GrammarSuggestion } from '../types/TextAnalysis';

const initialStats: TextStatistics = {
  wordCount: 0,
  characterCount: 0,
  characterNoSpaces: 0,
  sentenceCount: 0,
  paragraphCount: 0,
  readingTime: 0,
  speakingTime: 0,
  readabilityScore: 0,
  language: 'en',
};

export function useTextAnalysis() {
  const [text, setText] = useState(() => {
    if (typeof window === 'undefined') return '';
    return localStorage.getItem('word-counter-text') || '';
  });
  
  const [stats, setStats] = useState<TextStatistics>(initialStats);
  const [grammarSuggestions, setGrammarSuggestions] = useState<GrammarSuggestion[]>([]);
  const [styleSuggestions, setStyleSuggestions] = useState<Array<{
    original: string;
    suggestion: string;
    reason: string;
  }>>([]);

  useEffect(() => {
    const analyzeText = async () => {
      const newStats = calculateTextStatistics(text);
      setStats(newStats);
      setGrammarSuggestions(checkGrammar(text, newStats.language));
      
      if (text.length > 0) {
        const suggestions = await getStyleSuggestions(text);
        setStyleSuggestions(suggestions);
      } else {
        setStyleSuggestions([]);
      }
    };

    analyzeText();
    
    // Save text to localStorage
    localStorage.setItem('word-counter-text', text);
  }, [text]);

  const handleSetText = (newText: string) => {
    setText(newText);
  };

  return {
    text,
    setText: handleSetText,
    stats,
    grammarSuggestions,
    styleSuggestions
  };
}