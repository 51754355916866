import React from 'react';
import { FileText } from 'lucide-react';

export function Logo() {
  return (
    <div className="flex items-center gap-2">
      <div className="relative">
        <div className="absolute inset-0 bg-blue-500 rounded-lg transform rotate-3 transition-transform group-hover:rotate-6" />
        <div className="relative bg-blue-600 p-2 rounded-lg shadow-lg transform transition-transform group-hover:scale-105">
          <FileText className="w-6 h-6 text-white" />
        </div>
      </div>
      <div>
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white leading-none">
          WordCounter
          <span className="text-blue-600 dark:text-blue-400">.Cloud</span>
        </h1>
        <p className="text-xs text-gray-600 dark:text-gray-400 mt-0.5">
          Advanced Text Analysis
        </p>
      </div>
    </div>
  );
}