import React from 'react';
import { useTranslation } from 'react-i18next';
import { type TextStatistics } from '../types/TextAnalysis';
import { Clock, AlignLeft, Type, Hash, MessageSquare, Timer } from 'lucide-react';

interface StatisticsProps {
  stats: TextStatistics;
}

export function Statistics({ stats }: StatisticsProps) {
  const { t } = useTranslation();

  const StatCard = ({ icon: Icon, title, value }: { icon: any, title: string, value: string | number }) => (
    <div className="card p-4">
      <div className="flex items-center gap-2 mb-2">
        <Icon className="w-5 h-5 text-primary" />
        <h3 className="text-sm font-medium text-muted">{title}</h3>
      </div>
      <p className="text-xl sm:text-2xl font-bold">{value}</p>
    </div>
  );

  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-4">
      <StatCard icon={Type} title={t('stats.words')} value={stats.wordCount} />
      <StatCard icon={Hash} title={t('stats.characters')} value={stats.characterCount} />
      <StatCard icon={MessageSquare} title={t('stats.sentences')} value={stats.sentenceCount} />
      <StatCard icon={AlignLeft} title={t('stats.paragraphs')} value={stats.paragraphCount} />
      <StatCard icon={Clock} title={t('stats.readingTime')} value={`${stats.readingTime} ${t('stats.minutes')}`} />
      <StatCard icon={Timer} title={t('stats.speakingTime')} value={`${stats.speakingTime} ${t('stats.minutes')}`} />
    </div>
  );
}